import * as React from "react"
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"


// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,

} from "reactstrap";

import OtherPageHeader from "../components/otherPageHeader.js";
import Event from "../components/event.js"

const EventsPage = () => {
  const content = useStaticQuery(graphql`
    query EventsQuery {
      allEventsJson {
        edges {
          node {
            title
            date
            description
            docs {
              title
              link
            }
          }
        }
      }
    }
  `)
  console.log({content})

  //set initial states
  const [modal, setModal] = React.useState(false);
  const [eventModal, setEventModal] = React.useState([]);

  //handle clicks
  const handleEventClick = () => setModal(!modal);
  // const handleDateClick = (arg) => { // bind with an arrow function
  //   alert(arg.dateStr)
  // }

  // const futureEvents = content.allEventsJson.edges.filter( obj => obj.node.date === "October 8, 2024" )
  const futureEvents = content.allEventsJson.edges.filter( obj => new Date(obj.node.date) > new Date() )
  const pastEvents = content.allEventsJson.edges.filter( obj => new Date(obj.node.date) < new Date() )

  return (
    <>
      <Layout pageTitle="Events">
        <OtherPageHeader />
        <div className="main">
          <div className="section">
            <Container>
              { futureEvents.length ?
                <Row className="text-center">
                  <Col>
                    <h2 className="text-primary">Upcoming Events</h2>
                    <hr className="my-2 mx-5" />
                    {/* <p className="lead">Click on the event for more detail.</p> */}
                  </Col>
                </Row>
              : null
              }

              {/* Dynamic Future Events */}
              {futureEvents.map(edge => (
                <Row>
                  <Col>
                    <Event
                      key={edge.id}
                      title={edge.node.title}
                      date={edge.node.date}
                      description={edge.node.description}
                      docs={edge.node.docs}
                    />
                  </Col>
                </Row>
              ))}

              <Row className="text-center">
                <Col>
                  <h2 className="text-primary">Most Recent Events</h2>
                  <hr className="my-2 mx-5" />
                  {/* <p className="lead">Click on the event for more detail.</p> */}
                </Col>
              </Row>

              {/* Dynamic Past Events */}
              {pastEvents.map(edge => (
                <Row>
                  <Col>
                    <Event
                      key={edge.id}
                      title={edge.node.title}
                      date={edge.node.date}
                      description={edge.node.description}
                      docs={edge.node.docs}
                    />
                  </Col>
                </Row>
              ))}




              {/* <Row className="text-center">
                <Col>
                  <h2 className="text-primary">Calendar of Important Events</h2>
                  <hr className="my-2 mx-5" />
                  <p className="lead">Click on the event for more detail.</p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="9">
                  <FullCalendar
                    plugins={[ dayGridPlugin, interactionPlugin ]}
                    initialView="dayGridMonth"
                    // dateClick={handleDateClick}
                    events={[
                      { title: 'BOD Meeting', date: '2021-10-06' },
                      { title: 'Annual Meeting', date: '2021-10-10' }
                    ]}
                    eventClick={(e)=>{
                      if(e){
                        handleEventClick()
                        setEventModal([e.event])
                        console.log(e.event)
                      }
                    }}
                  />
                </Col>
              </Row> */}
            </Container>
          </div>
        </div>
      </Layout>
      {
        eventModal.map( (event, id) =>(
          <Modal isOpen={modal} toggle={handleEventClick} key={id}>
          {/* <Modal isOpen={modal} toggle={handleEventClick} className={className} key={id}> */}
            <ModalHeader toggle={handleEventClick}>{event.title}</ModalHeader>
            <ModalBody>
              <p>{event.date}</p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ModalBody>
            {/* <ModalFooter>
              <Button color="secondary" onClick={toggle}>Cancel</Button>
            </ModalFooter> */}
          </Modal>
        ))
      }
    </>
  )
}

export default EventsPage
