import { computeEdges } from '@fullcalendar/react';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
//import { container } from './site.scss'

// reactstrap components
// import {
//   Collapse,
//   CardBody,
//   Card,
//   CardHeader,
//   CardTitle,
//   CardText,
// } from "reactstrap";

import {
    Button
} from "reactstrap";



const Doc = (props) => {

    const data = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: {eq: "docs"} }) {
        edges {
          node {
            publicURL
            base
          }
        }
      }
    }
  `)

  const filteredData = data.allFile.edges.filter( obj => obj.node.base === props.link )
  //   const filteredData = data.allFile.edges.filter( obj => obj.node.base == "EHR_HOA_Oct_2023_membership_meeting.pdf" )

  return (

    <Button className="h5" href={filteredData[0].node.publicURL} target="_blank" rel="noopener noreferrer" color="link">
        <i className="nc-icon nc-minimal-right" />{props.title}
    </Button>

  );
}

export default Doc;