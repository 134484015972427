import { computeEdges } from '@fullcalendar/react';
import React, { useState } from 'react';
//import { container } from './site.scss'

// reactstrap components
// import {
//   Collapse,
//   CardBody,
//   Card,
//   CardHeader,
//   CardTitle,
//   CardText,
// } from "reactstrap";

import {
    Button
} from "reactstrap";

import Doc from './doc';



const Event = (props) => {

  const formattedDate = new Date(props.date).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"}) 

  return (

    <div class="card card-nav-tabs">
        <div class="card-header card-header-warning">
        {formattedDate}
        </div>
        <div class="card-body">
            <h4 class="card-title">{props.title}</h4>
            <p>
                <div 
                    dangerouslySetInnerHTML={{__html: props.description}}
                />
            </p>

            {props.docs.map(edge => (
                <Doc 
                    title={edge.title}
                    link={edge.link}
                />
            ))}
        </div>
    </div>

  );
}

export default Event;